// src/components/Home/Home.js
import React from "react";
import Header from "../Header/Header";
import About from "../About/About";
import Projects from "../Projects/Projects";
import Contact from "../Contact/Contact";

const Home = () => {
  return (
    <div className="overflow-x-hidden">
      <Header />
      <About />
      <Projects />
      <Contact />
    </div>
  );
};

export default Home;
