import React from "react";
import alien from "../../assets/alien.svg";
import "./About.css";
import { motion } from "framer-motion";
import { fadeIn } from "../../variants";

const About = () => {
  return (
    <div className="w-full text-center py-10 h-full">
      {/* // <div className="flex flex-col w-full justify-center text-center md:mt-[1px] md:min-h-[50vh] lg:min-h-[60vh] xl:min-h-[100vh]"> */}
      {/* <section> */}
      <motion.h1
        variants={fadeIn("up", 0.4)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.7 }}
        className="title text-sm md:text-4xl font-thin "
      >
        Бидний хамт олон
      </motion.h1>
      <motion.p
        variants={fadeIn("left", 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.2 }}
        className="title text-[10px] md:text-xl mt-[50px] md:mt-[7%] w-full md:w-[60%] mx-auto leading-8 lg:leading-10"
      >
        Бидний хамт олон нь 2023 онд байгуулагдсан бөгөөд өдгөө хүртэл бид олон төслүүдийг гаргасан билээ.
        Бидний баг нь 6-н хөгжүүлэгч 2 дизайнараар мэргэжлийн өндөр төвшний хамт олон үйлчилгээ үзүүлсээр байна.
      </motion.p>
      <div className="md:mt-10">
        <motion.img
          variants={fadeIn("up", 0.4)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          src={alien}
          alt="Alien Icon"
          className="mx-auto md:h-[400px]"
        />
      </div>
      {/* </section> */}
    </div>
  );
};

export default About;
