import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Header from "./Pages/Header/Header";
// import About from "./Pages/About/About";
// import Contact from "./Pages/Contact/Contact";
// import Projects from "./Pages/Projects/Projects";
import Home from "./Pages/Home/Home";

function App() {
  return (
    <div className="bg-gradient-to-b from-black to-[#264a39] text-white font-sans max-w-full">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          {/* <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Projects />} />
          <Route exact path="/contact" element={<Contact />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
