import React from "react";
import logo from "../../assets/logo.png";
import "@fontsource/press-start-2p";
import "../../index.css";
import { motion } from "framer-motion";
import { fadeIn } from "../../variants";

const Header = () => {
  return (
    <div className="flex flex-col w-full justify-center text-center md:mt-[1px] md:min-h-[50vh] lg:min-h-[60vh] xl:min-h-[100vh]">
      <motion.div
        variants={fadeIn("up", 0.4)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.7 }}
        className="flex items-center justify-center text-6xl font-bold mt-[20px]"
      >
        <img
          src={logo}
          className="h-[150px] lg:h-[300px] 2xl:h-[400px]"
          alt=""
        />
        <div className="flex flex-col ml-5 md:mt-[90px]">
          <span className="title font-thin text-[16px] md:text-[40px] lg:text-[45px] 2xl:text-[80px] mb-[10px] md:mb-[50px] ">
            SOLUTIONS LAB
          </span>
          <span className="title text-[6px] xl:text-[20px] md:text-[12px] font-light text-end underline underline-offset-8 underline-w-[1px]">
            SINCE 2024
          </span>
        </div>
      </motion.div>
      <motion.div
        variants={fadeIn("left", 0.4)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.4 }}
        className="w-full barcode text-[32px] 2xl:text-[130px] lg:text-[80px] md:text-[60px] md:mt-[30px] mt-[10px] tracking-widest"
      >
        STORY OF THE FUTURE
      </motion.div>
    </div>
  );
};

export default Header;
