// src/components/Contact/Contact.js
import React from "react";
import planet from "../../assets/planet.svg";
import phone from "../../assets/phone.svg";
import web from "../../assets/web.svg";
import mail from "../../assets/mail.svg";
import location from "../../assets/location.svg";
import { fadeIn } from "../../variants";
import { motion } from "framer-motion";

const Contact = () => {
  return (
    <div className="flex flex-col justify-center text-center md:mt-[200px] ">
      <motion.h1
        variants={fadeIn("up", 0.8)}
        initial={"hidden"}
        whileInView={"show"}
        viewport={{ once: false, amount: 0.7 }}
        className="title md:text-4xl font-bold"
      >
        Холбоо барих
      </motion.h1>
      <div className="flex flex-col min-h-screen justify-start">
        <div className="flex flex-col justify-center items-center w-full">
          <div className="text-left title ml-[10px] md:mt-[15vh] md:space-y-[50px] space-y-8 mt-[60px]">
            <motion.div
              variants={fadeIn("right", 0.8)}
              initial={"hidden"}
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="flex items-center gap-4   "
            >
              <img src={phone} alt="" className="md:w-[40px] w-[30px] " />
              <span className="md:text-sm text-[12px] mt-2">+976 60071001</span>
            </motion.div>
            <motion.div
              variants={fadeIn("left", 0.8)}
              initial={"hidden"}
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="flex items-center gap-4"
            >
              <img src={mail} alt="" className="md:w-[40px] w-[30px] " />
              <span className="md:text-sm text-[12px] md:mt-2">
                matrixsolutionslabs@gmail.com
              </span>
            </motion.div>
            <motion.div
              variants={fadeIn("right", 0.8)}
              initial={"hidden"}
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="flex items-center gap-4 "
            >
              <img src={web} alt="" className="md:w-[40px] w-[30px] " />
              <span className="md:text-sm text-[12px] md:mt-2">
                www.matrixsolutionslab.com
              </span>
            </motion.div>
            <motion.div
              variants={fadeIn("left", 0.8)}
              initial={"hidden"}
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="flex items-center gap-4"
            >
              <img
                src={location}
                alt=""
                className="justify-center  md:mt-[0px] md:w-[40px] w-[30px] "
              />
              <span className="md:text-sm  text-[12px] md:mt-2 leading-5">
                Baruun selbe's street 5-5, 4th microdistrict, Ulaanbaatar,
                Mongolia
              </span>
            </motion.div>
          </div>
        </div>
        <div className="flex w-full md:mt-10 mt-[80px] z-2 absolute">
          <motion.img
            variants={fadeIn("up", 0.8)}
            initial={"hidden"}
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
            src={planet}
            alt="Alien Icon"
            className="mx-auto "
          />
        </div>
      </div>
    </div>
  );
};

export default Contact;
