// src/components/Projects/Projects.js
import React, { useState } from "react";
import "./Projects.css";
import slide1 from "../../assets/slide1.svg";
import slide2 from "../../assets/slide2.svg";
import slide3 from "../../assets/slide3.svg";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { fadeIn } from "../../variants";
import { motion } from "framer-motion";

const slides = [slide1, slide2, slide3, slide1, slide2];

const Projects = () => {
  const [page, setPage] = useState(1);
  const itemsPerPage = 3;

  const handleChange = (event, value) => {
    setPage(value);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = slides.slice(startIndex, endIndex);

  return (
    <div className="flex flex-col h-screen">
      <section className="text-center py-10">
        <motion.h1
          variants={fadeIn("up", 0.4)}
          initial={"hidden"}
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="title md:text-4xl text-[13px] font-bold"
        >
          Бидний гүйцэтгэсэн төслүүд
        </motion.h1>
        <motion.div
          variants={fadeIn("up", 0.4)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="title md:text-[12px] text-[6px] md:mt-8 mt-1 underline underline-offset-8"
        >
          SINCE 2023
        </motion.div>
        <div className="md:flex flex-col flex min-h-[70vh] w-screen mt-[80px] justify-center items-center">
          <div className="slide-container md:flex h-full items-center flex max-md:flex-col justify-center w-full">
            {currentItems.map((slide, index) => (
              <motion.img
                variants={fadeIn("up", 0.7)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.7 }}
                key={index}
                src={slide}
                alt={`Project Slide ${startIndex + index + 1}`}
                className="md:max-w-[30%] max-w-[70%] h-auto justify-center"
              />
            ))}
          </div>
          <motion.div
            variants={fadeIn("up", 0.4)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
            className="flex flex-col justify-center items-center mt-10"
          >
            <Stack spacing={2} className="mt-10 flex flex-col">
              <Pagination
                count={Math.ceil(slides.length / itemsPerPage)}
                page={page}
                className="flex flex-col"
                onChange={handleChange}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                    className="flex flex-col"
                  />
                )}
              />
            </Stack>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default Projects;
